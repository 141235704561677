<template>
	<v-sheet class="recurring-main-listing" style="height: calc(100vh - 88px)">
		<v-layout class="">
			<v-flex class="py-0">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					item-text="text"
					item-value="value"
					:disabled="pageLoading"
					:items="recurring_status_list"
					label="All Recurring"
					solo
					v-on:change="statusFilterRows($event)"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.text"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
								<span>{{ item.status_count }}</span>
							</v-avatar>
						</v-list-item-action>
					</template>
				</v-select>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="py-0 my-auto d-flex text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-flex class="d-flex justify-content-end mr-1">
						<!-- <v-autocomplete
							:items="projectTypeList"
							hide-details
							outlined
							v-model="project_type"
							class="mr-2"
							placeholder="Project Type"
						></v-autocomplete> -->
						<v-autocomplete
							:items="paymentTermsList"
							hide-details
							outlined
							v-model="payment_terms"
							class="mr-2"
							item-text="name"
							item-value="value"
							placeholder="Payment Terms"
							v-on:change="paymentFilterRows($event)"
						></v-autocomplete>
						<!-- 	<v-btn depressed color="blue darken-4" class="text-white mx-0" tile min-height="30">
							<v-icon>mdi-magnify</v-icon>
						</v-btn> -->
					</v-flex>
					<v-btn
						depressed
						tile
						color="blue darken-4"
						class="text-white"
						min-height="30"
						:to="{
							name: 'create-recurring',
						}"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						min-height="30"
						v-on:click="exportCancel"
					>
						<v-icon left>mdi-download</v-icon>
						Export
					</v-btn>
					<!-- <v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="routeSetting()"
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn> -->
					<v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="drawerFilter = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn>
					<v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="viewType = !viewType"
					>
						<v-icon v-if="viewType">mdi-view-list</v-icon>
						<v-icon v-else>mdi-grid</v-icon>
					</v-btn>
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('member')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('member')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</template>
			</v-flex>
		</v-layout>
		<v-layout class="page-summary show" v-if="false">
			<v-flex
				:class="`summary-details ${item.color} lighten-5`"
				v-for="(item, index) in status_list"
				:key="index"
			>
				<v-icon size="30" :color="item.color">mdi-file-chart-outline</v-icon>
				<div :class="`name ${item.color}--text no-wrap`">{{ item.name }}</div>
				<div :class="`number ${item.color}--text`">{{ item.total_count }}</div>
			</v-flex>
		</v-layout>
		<Dialog :dialog="export_dialog">
			<template v-slot:title> Import Recurrings </template>
			<template v-slot:body> Export </template>
			<template v-slot:action>
				<v-btn tile depressed class="mr-3" @click="export_dialog = false">Close</v-btn>
				<v-btn tile depressed color="blue darken-4 white--text" class="">Import</v-btn>
			</template>
		</Dialog>
		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Member #, Name, Company Name, Display Name and Email Address"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<!-- <template v-if="viewType">
			<RecurringKanban></RecurringKanban>
		</template> -->
		<div class="mb-3 overflow-auto">
			<v-btn-toggle v-model="toggle_exclusive" mandatory color="primary" dense tile>
				<v-btn class="blue--text btn-border" v-on:click="alphabet()"> ALL </v-btn>
				<v-btn
					class="btn-border"
					color="blue darken-4 white--text"
					v-for="(data, index) in filter"
					:key="index"
					v-on:click="alphabet(data)"
				>
					{{ data }}
				</v-btn>
			</v-btn-toggle>
		</div>
		<template>
			<Table
				type="recurring"
				detail-route="cancel-detail"
				delete-endpoint="recurring/"
				v-on:reload:content="filterRows"
				delete-note="All transactions of this member will also be deleted."
			></Table>
		</template>

		<ExportDialog
			endpoint="member/export?type=member&current=1"
			title="Members"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<ExportDialog
			endpoint="member/export?type=member"
			title="Members"
			:export-dialog.sync="exportDialog"
			v-on:close="exportDialog = false"
		></ExportDialog>
		<ImportDialog
			endpoint="member/import?type=member"
			title="Members"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			title="Members"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
		<AdvanceFilter
			:advanceSearch="drawerFilter"
			v-on:close="drawerFilter = false"
			search-endpoint="recurring"
			search-title="Recurring"
		></AdvanceFilter>
	</v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
/* import RecurringKanban from "@/view/module/recurring/RecurringKanban"; */
import ListingMixin from "@/core/mixins/listing.mixin";
import { GET, QUERY } from "@/core/services/store/request.module";
import { SET_TBODY } from "@/core/services/store/listing.module";
import AdvanceFilter from "@/view/pages/leads/AdvanceFilter";
import JwtService from "@/core/services/jwt.service";

export default {
	name: "Recurring-listing",
	title: "Listing Recurring",
	mixins: [ListingMixin],
	data() {
		return {
			export_dialog: false,
			toggle_exclusive: 0,
			filter: [
				"A",
				"B",
				"C",
				"D",
				"E",
				"F",
				"G",
				"H",
				"I",
				"J",
				"K",
				"L",
				"M",
				"N",
				"O",
				"P",
				"Q",
				"R",
				"S",
				"T",
				"U",
				"V",
				"W",
				"X",
				"Y",
				"Z",
			],
			viewType: true,
			search_query: null,
			projectTypeList: ["SEO", "Web", "Domain"],
			project_type: null,
			drawerFilter: false,
			paymentTermsList: [
				{ value: "monthly", name: "Monthly" },
				{ value: "quarterly", name: "Quarterly" },
				{ value: "half_yearly", name: "Half Yearly" },
				{ value: "yearly", name: "Yearly" },
			],
			payment_terms: null,
			pageTitle: "Recurring",
			pageBreadcrumbs: [{ text: "Recurring", disabled: true }],
			endpoint: "recurring-cancel",
			defaultFilter: {},
			status: "4",
			recurring_status_list: [],
			status_list: [
				{ id: 1, name: "All Recurring", total_count: 15, color: "blue" },
				{ id: 2, name: "New", total_count: 15, color: "cyan" },
				{ id: 3, name: "Recurring", total_count: 15, color: "green" },
				{ id: 4, name: "Cancelled New", total_count: 15, color: "red" },
				{ id: 6, name: "Invoice to be issued", total_count: 15, color: "orange" },
				{ id: 5, name: "Cancelled Recurring", total_count: 15, color: "red" },
				{ id: 7, name: "Overdue", total_count: 15, color: "cyan" },
				{ id: 8, name: "Due Payment", total_count: 15, color: "orange" },
				{ id: 9, name: "Past deadline Payment", total_count: 15, color: "red" },
			],
			pageLoading: false,
		};
	},
	methods: {
		exportCancel() {
			let downloadURL = process.env.VUE_APP_API_URL + "cancelled-export";
			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			downloadURL.searchParams.append("token", token);
			window.open(downloadURL, "_blank");
		},
		alphabet(value) {
			this.$store
				.dispatch(QUERY, {
					url: `recurring-renew`,
					data: {
						alphabet: value,
					},
				})
				.then((data) => {
					this.$store.commit(SET_TBODY, data.tbody);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getRecurringStatus() {
			this.$store
				.dispatch(GET, { url: "recurring-setting" })
				.then((data) => {
					this.recurring_status_list = data.status;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		statusFilterRows(value) {
			this.$store
				.dispatch(QUERY, {
					url: `recurring`,
					data: {
						status: value,
					},
				})
				.then((data) => {
					this.$store.commit(SET_TBODY, data.tbody);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		paymentFilterRows(value) {
			this.$store
				.dispatch(QUERY, {
					url: `recurring`,
					data: {
						payment_type: value,
					},
				})
				.then((data) => {
					this.$store.commit(SET_TBODY, data.tbody);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
	},
	components: {
		//RecurringKanban,
		Dialog,
		AdvanceFilter,
	},
	mounted() {
		this.getRecurringStatus();
	},
};
</script>
